
// p-dropdown {
//   /* Tus estilos aquí */
//   @apply kama-dropdown;
// }

/* Clases para dropdowns */
.kama-dropdown {
  border-color: $accent-color !important;
  border-width: 1px !important;
  border-radius: 0.5rem !important;
  border-style: solid;
  height: 2.6rem;
  width: 100%;
  background-color: white;

  &.rounded-lg {
    border-radius: 0.5rem !important;
  }

  .p-dropdown-trigger {
    border-color: transparent !important;
  }
}


.kama-invalid-dropdown {
  border-color: red !important;
  border-width: 2px !important;
  border-radius: 0.5rem !important;
  border-style: solid;
  height: 2.6rem;
  width: 100%;
  background-color: $invalid-color !important;
}




// .p-dropdown .p-dropdown-label {
//   border: none !important;
// }

::ng-deep .p-dropdown {
  border: 2px solid #ccc !important; /* Ajusta el color del borde según tus necesidades */
  border-radius: 0.375rem; /* Si quieres redondear los bordes */
}

::ng-deep .p-dropdown {
  border: 2px solid #ccc !important; /* Ajusta el color del borde según tus necesidades */
  border-radius: 0.375rem; /* Si quieres redondear los bordes */
}

.p-dropdown.p-disabled {
  color: black !important; // Cambia el color del texto
}

// También puedes apuntar al label específicamente
.p-dropdown.p-disabled .p-dropdown-label {
  color: black !important;
}

// Dropdown deshabilitado
// select:disabled, .p-dropdown.p-disabled {
//   background-color: #e0e0e0; // Fondo gris más oscuro
//   color: #9a9a9a; // Color de texto más claro
//   border-color: #bcbcbc; // Borde gris para diferenciarlos
// }
