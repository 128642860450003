@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

@import 'primeicons/primeicons.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Aquí se importan los estilos personalizados al final para que tengan mayor prioridad */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

