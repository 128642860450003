//añade bordes redondeados por defecto para el componente p-inputNumber
// .kama-input-number {
//   border-radius: 0.5rem;
// }

.p-inputnumber-input {
  border-radius: 0.5rem;
  width: 100%;
  // height: 38px; // Match your other inputs
}

// .p-component {
//     & .ng-star-inserted {
//       border-radius: 0.5rem;
//     }
// }

// // Estilo para cuando está enfocado
// :host ::ng-deep .p-inputnumber:not(.p-disabled).p-focus .p-inputnumber-input {
//   border-color: var(--primary-color, #007bff);
//   box-shadow: 0 0 0 1px var(--primary-color, #007bff);
// }

// // Estilo para la versión invalid
// :host ::ng-deep .p-invalid .p-inputnumber-input {
//   border-color: var(--danger-color, #dc3545);
// }

.kama-input {
  &.ng-dirty.ng-invalid {
    border-color: red !important;
    border-width: 2px !important;
  }
}

.p-inputtext[enabled] {
  font-weight: 900;
}


.kama-any-input-invalid {
  border-color: red !important;
  border-width: 2px !important;
  background-color: $invalid-color !important;
}


.p-calendar .p-inputtext[disabled] {
  background-color: $disabled-color;
  color: black;
  border: 1px solid #b0b0b0;
}


// Inputs deshabilitados (general)
input:disabled, textarea:disabled, .p-inputtext[disabled] {
  background-color: $disabled-color; // Un fondo gris diferente al habilitado
  // color: #a0a0a0; // Texto gris más claro
  color: black;
  border: 1px solid #b8b8b8; // Borde más notorio
}
