/*
 * Estilos para botones consistentes
 * Añadir este bloque a tu archivo styles.scss global
 */
/* Estilo global para los botones */
button, .p-button {
  background-color: var(--blue-500); /* Asegúrate de que esta variable esté definida en tu tema */
  color: white; //este no es
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.p-button {
  height: 2.5rem;        /* Altura usando rem en lugar de px */
  min-width: 2.5rem;     /* Ancho mínimo usando rem */
  font-size: 0.875rem;   /* Tamaño de fuente consistente */
  padding: 0.5rem 1rem;  /* Padding interior consistente */
  transition: all 0.2s;  /* Transición suave para hover/focus */
}

/* Botones más pequeños en móvil */
@media screen and (max-width: 768px) {
  .p-button {
    height: 2.25rem;
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }

  /* Priorizar iconos en pantallas pequeñas */
  :host ::ng-deep .p-button .p-button-label {
    display: none;
  }

  /* Solo mostrar label para botones más importantes */
  :host ::ng-deep .p-button.p-button-primary .p-button-label,
  :host ::ng-deep .p-button.p-button-danger .p-button-label {
    display: inline-block;
  }
}

/* Hover consistente para todos los botones */
.p-button:hover:not([disabled]) {
  transform: translateY(-0.125rem);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

/* Estilos para botones deshabilitados más sutiles */
.p-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

button:hover, .p-button:hover {
  background-color: $secondary-color; /* Color de fondo al pasar el ratón */
}

button:disabled {
  background-color: #d3d3d3; // Un gris más oscuro para mayor contraste
  color: #7a7a7a; // Color de texto gris oscuro
  opacity: 0.7; // Disminuye la opacidad para dar apariencia deshabilitada
  cursor: not-allowed; // Cambia el cursor para indicar que está deshabilitado
}
