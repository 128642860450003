/*background-colors*/
$primary-background-color: #223A73;
$primary-light: #22418b;
$dark-background: #192e61;
$accent-color: #1d6368;
// $accent-alternate: #8FB8DE;
$alternate-background-grey: #77787f;


$secondary-color: #048BA8;
$text-color: #ffffff;
$secondary-text-color: #000000;
$error-color: #ff0000;
$background-white: #faf9f9;
$disabled-color: #f0f0f0;
$invalid-color: #f9cad6;

:root {
  --invalid-color: $invalid-color;
  --primary-background-color: $primary-background-color;
  --primary-light: $primary-light;
  --dark-background: $dark-background;
}
