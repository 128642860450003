.p-datatable-global {
  @include rounded-corners(8px);
  @include table-theme($dark-background, $dark-background, $accent-color);

  // Estilos generales para la tabla
  &.p-datatable {
    font-family: 'Inter', 'Segoe UI', Roboto, -apple-system, sans-serif !important; // Fuente moderna
    font-size: 0.95rem !important;
  }
  
  // Establecer estilos para el wrapper de la tabla
  .p-datatable-wrapper {
    @include rounded-corners(8px);
    background-color: $dark-background !important;
  }

  // Estilos para el paginador
  .p-paginator {
    @include paginator-theme($dark-background, $accent-color);
  }

  // Estilos para inputs dentro de la tabla
  .p-column-filter {
    width: 100% !important;
    margin-top: 0.5rem !important;
  }
  
  input.p-column-filter,
  .p-column-filter input,
  input[type="text"],
  .p-calendar .p-inputtext {
    @include modern-input;
    width: 100% !important;
  }
  
  // Estilos para los botones de acción
  .p-button {
    border-radius: 50% !important; // Los botones circulares se ven más modernos
    width: 2.5rem !important;
    height: 2.5rem !important;
    margin: 0 0.2rem !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.2s ease !important;
    
    &:hover {
      transform: translateY(-2px) !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
    }
    
    .p-button-icon {
      font-size: 1rem !important;
    }
  }
  
  // Alineación y estilos adicionales del header
  .p-datatable-thead {
    > tr > th {
      text-align: left !important;
      
      // Centrar columnas numéricas
      &.column-numeric {
        text-align: right !important;
      }
    }
    
    // Estilo específico para la primera fila (ordenación)
    > tr:first-child > th {
      background-color: darken($dark-background, 10%) !important; // Más oscuro que el fondo
    }
    
    // Estilo específico para la fila de filtros
    > tr:nth-child(2) {
      > th {
        background-color: $dark-background !important; // El mismo color que el fondo de la tabla
      }
      
      // Efecto de tarjeta elevada para los inputs
      .p-column-filter {
        background-color: rgba(darken($dark-background, 5%), 0.8) !important;
        padding: 6px !important;
        border-radius: 6px !important;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) !important;
      }
    }
  }
  
  // Alineación de celdas
  .p-datatable-tbody > tr > td {
    &.column-numeric {
      text-align: right !important;
    }
    
    &.column-actions {
      text-align: center !important;
    }
  }
  
  // Estilo para filas seleccionadas
  .p-datatable-tbody > tr.p-highlight {
    background-color: rgba($accent-color, 0.2) !important;
  }
  
  // Efecto de carga
  .p-datatable-loading-overlay {
    background-color: rgba(grey, 0.8) !important;
    border-radius: 8px !important;
    
    .p-datatable-loading-icon {
      color: white !important;
      font-size: 2rem !important;
    }
  }

  // Si la tabla está dentro de una tarjeta, ajusta el contraste
  .kama-card & {
    // En caso de estar dentro de una tarjeta, aumentamos el contraste
    .p-datatable-thead {
      > tr:first-child > th {
        background-color: darken($dark-background, 15%) !important; // Aún más oscuro
      }
    }
  }
}

// Estilos para el botón "Nuevo Catálogo"
button.p-button.p-component {
  border-radius: 6px !important;
  padding: 0.6rem 1.2rem !important;
  font-weight: 500 !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  transition: all 0.2s ease !important;
  
  &:hover {
    transform: translateY(-1px) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
  }
  
  .p-button-icon {
    font-size: 1rem !important;
  }
}